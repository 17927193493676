import { Controller } from "stimulus";

export default class ContractFormController extends Controller {
  static targets = ["subscriptionPrice", "periodicity", "monthlyFixePrice"]
  static values =  { newRecord: Boolean}

  connect() {
    if (this.hasNewRecordValue && this.newRecordValue) {
      this.hookStartDatePicker()
    }
  }

  updateMonthlyPrice() {
    let subPrice = this.subscriptionPriceTarget.value
    let perio = this.periodicityTarget.value || 1
    let monthly_price = subPrice / perio
    this.monthlyFixePriceTarget.value= monthly_price.toFixed(2)
  }

  hookStartDatePicker() {
    $("#contract.new_contract #contract_start_date").datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    }
    )
  }
}
