import dt from 'datatables';
import {} from 'datatables-bootstrap';
import { Controller } from "stimulus";

export default class ContractsController extends Controller {
  static targets = ["contractsTable"]

  initialize() {
    // datatable ref
    this.dtable = null
  }

  connect() {
    this.hookDataTable()
  }

  disconnect() {
    // drop dtable
    //this.dtable.api().destroy()
  }

  hookDataTable() {
    dt.$(this.contractsTableTarget).DataTable({paging: true})
  }
}
