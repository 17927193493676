import { Controller } from "stimulus";

export default class BatchInvoicesOperationsDetailsController extends Controller {
  //static targets = ["content"]

  connect() {
    console.log("connect details")
  }

  disconnect() {
    console.log("disconnect details ")
  }

}
