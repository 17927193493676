import { Controller } from "stimulus";

export default class RestaurantOptionFormController extends Controller {

  connect() {
    console.log('connect ro form')
    this.hookDatePickers()
  }

  hookDatePickers() {
    $("#restaurant_option_start_date").datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    })
    $("#restaurant_option_end_date").datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    })
  }
}
