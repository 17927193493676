import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class BatchInvoicesOperationsFormController extends Controller {
  static targets = ["entity", "operation", "operationForm", "operationsDetails"]
  static values =  { newRecord: Boolean}

  connect() {
    //console.log('connect bfc')
  }

  handleSelectionChange() {
    Rails.fire(this.operationFormTarget, 'submit');
  }

  updateHistory() {
    var queryParams = new URLSearchParams()
    queryParams.set("entity", this.entityTarget.value)
    queryParams.set("operation", this.operationTarget.value)
    history.pushState(null, null, "?"+queryParams);
  }

  onFormReload(event) {
    this.updateHistory()
    let [data, status, xhr] = event.detail;
    this.operationsDetailsTarget.innerHTML = xhr.response
  }
}
