import { Controller  } from 'stimulus';

export default class BillingInfoFormController extends Controller {

  static targets= ["paiementTypeInput", "debitFields"]

  connect() {
    this.hookSignDatePicker()
    this.handlePaiementTypeChange()
    //this.hookPaiemenType()
  }

  hookSignDatePicker() {
    $("#billing_info_mandat_sign_date").datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
    })
  }

  handlePaiementTypeChange() {
    this.debitFieldsTarget.hidden = this.paiementTypeInputTarget.value != 'debit'
  }

}
